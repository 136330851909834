export function searchResults() {
    const app = new Vue({
        el: '#searchResults',
        data: {
            resultList: [],
            initialData: [],
            latestTopics: [],
            latestTypes: [],
            latestDates: [],
            page: 1,
            topic: "all",
            type: "all",
            date: "all",
            searchTerm: "",
            service: $('#searchResults').attr('data-service'),
            updateTopic: true,
            updateType: true,
            updateDate: true,
            numCount: 0
        },
        created: function () {
            const params = new URL(window.location).searchParams;
            this.searchTerm = params.get('searchTerm');
            $('#mainSearch').val(this.searchTerm);
            console.log('this.searchTerm: ',this.searchTerm);
            this.mainSearch();
            if( this.searchTerm.length != 0 ) $('#clearFilters').removeClass('d-none');
            else $('#clearFilters').addClass('d-none');
        },
        mounted: function () {
            let self = this;
            $('#mainSearch').keypress(function (e) {
                if (e.which == 13) {
                    self.mainSearch();
                }
            });
        },
        methods: {
            moreBtnState: function (num, total) {
                if (num < total) {
                    $('#loadMore').removeClass('d-none');
                } else {
                    $('#loadMore').addClass('d-none');
                }
                if (total < 10) {
                    $('#loadMore').addClass('d-none');
                }
            },
            displayResults: function () {
                $('#searchResults img.loader').show();
                let self = this;
                this.searchTerm = $('#mainSearch').val();
                let dataParameters = {
                    'page': this.page,
                    'topic': this.topic,
                    'type': this.type,
                    'date': this.date,
                    'searchTerm': this.searchTerm
                };
                $.get(this.service, dataParameters, function (data) {

                        if (data.ResultsResponse.Results.length != 0 && self.page > 1) {
                            self.resultList.Results = self.resultList.Results.concat(data.ResultsResponse.Results);
                            self.numCount = self.resultList.Results.length;
                            // self.page = page;
                            console.log('num:' + self.numCount, self.resultList.TotalCount, self.resultList.Results);
                            self.moreBtnState(self.numCount, self.resultList.TotalCount);
                        } else {
                            // console.log('Search Results', data);
                            self.resultList = data.ResultsResponse;
                            self.numCount = self.resultList.Results.length;
                            self.moreBtnState(self.numCount, self.resultList.TotalCount);
                            console.log('Search Results', self.numCount, self.resultList.Topics, dataParameters);
                            if (self.updateTopic === false) {
                                self.resultList.Topics = self.latestTopics;
                            }
                            if (self.updateType === false) {
                                self.resultList.Types = self.latestTypes;
                            }
                            if (self.updateDate === false) {
                                self.resultList.Dates = self.latestDates;
                            }
                        }
                    })
                    .always(function () {
                        $('#searchResults img.loader').hide();
                    });
            },
            mainSearch: function () {
                let self = this;
                this.page = 1;
                this.topic = this.type = this.date = "all";
                // this.service = $('#searchResults').attr('data-service');
                this.displayResults();
                $('#topicDropdown, #typeDropdown, #dateDropdown').prop("selectedIndex", 0);
                this.resultList.Results = [];
                this.updateTopic = true;
                this.updateType = true;
                this.updateDate = true;

                if( this.searchTerm.length != 0 ) $('#clearFilters').removeClass('d-none');
                else $('#clearFilters').addClass('d-none');

                // setTimeout(function () {
                //     self.initialData = self.resultList;
                //     console.log('res:', self.initialData);
                // }, 100);

            },
            //Load More
            loadMore: function (e) {
                e.preventDefault();
                this.page++;
                this.displayResults();
            },
            // Topic Dropdown
            topicSelect: function (event) {
                console.log('topic clicked! event: ', event.target.value);
                this.page = 1;
                this.topic = event.target.value;
                // this.service = '../data/searchResultsResponse.json';
                this.displayResults();
                this.updateTopic = false;
                this.updateType = true;
                this.updateDate = true;
                $('#clearFilters').removeClass('d-none');
                this.latestTopics = this.resultList.Topics;
            },
            // Type Dropdown
            typeSelect: function (event) {
                console.log('type clicked! event: ', event.target.value);
                this.page = 1;
                this.type = event.target.value;
                // this.service = '../data/searchResultsResponse.json';
                this.displayResults();
                this.updateTopic = true;
                this.updateType = false;
                this.updateDate = true;
                $('#clearFilters').removeClass('d-none');
                this.latestTypes = this.resultList.Types;
            },
            // Date Dropdown
            dateSelect: function (event) {
                console.log('type clicked! event: ', event.target.value);
                this.page = 1;
                this.date = event.target.value;
                // this.service = '../data/searchResultsResponse.json';
                this.displayResults();
                this.updateTopic = true;
                this.updateType = true;
                this.updateDate = false;
                $('#clearFilters').removeClass('d-none');
                this.latestDates = this.resultList.Dates;
            },
            // Date Dropdown
            clearFilters: function () {
                $('#mainSearch').val("");
                this.searchTerm = ""

                this.page = 1;                                
                this.topic = this.type = this.date = "all";
                this.displayResults();
                
                $('#topicDropdown, #typeDropdown, #dateDropdown').prop("selectedIndex", 0);
                this.updateTopic = true;
                this.updateType = true;
                this.updateDate = true;
                
                $('#clearFilters').addClass('d-none');
            }
        }
    });
}