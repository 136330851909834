
var _ = require('lodash');

// Events
export class Events{

    //properties
    monthsList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    mainListEvents = [];
    filteredEvents = [];
    stringEventsDate = [];
    stringTodaysDate = [];
    eventsDate = "";  // new Date('03-01-2021'); //new Date('Apr 01 2021');; //new Date('Mon Apr 12 2021'); //'Mon Apr 12 2021' // --> receive from BE
    todaysDate = "";  // new Date('03-13-2021'); // current date from BE

    year = '';
    month = '';
    day = '';

    constructor(EventsResponse) {
        //---- fill data:
        this.mainListEvents =  EventsResponse.Events;  
        this.filteredEvents =  EventsResponse.Events;       
        
        this.stringEventsDate = EventsResponse.EventsDate.split(/-/);        
        this.eventsDate = new Date(this.stringEventsDate[2], this.stringEventsDate[0], this.stringEventsDate[1] ); //year/month/day 
        
        this.stringTodaysDate = EventsResponse.TodaysDate.split(/-/);
        this.todaysDate = new Date(this.stringTodaysDate[2], this.stringTodaysDate[0], this.stringTodaysDate[1] ); //year/month/day 

        this.year = this.eventsDate.getFullYear();        
        this.month = ("0" + (this.eventsDate.getMonth() )).slice(-2) // 0=jan, 1=feb, 2=mar, 3 apr, 4=may // should be calculated and put 01,02,03... 10,11,12        
        this.day = 1; 
    }

    init(){
        var self = this;

        //---- select correct year
        $('#eventsCalendarYear').val(this.year);
        
        //---- select correct month eventsDate
        $('#eventsCalendarMonth').val(+this.month);

        //---- update caption
        $('.events-grid-table caption').text(this.monthsList[+this.month] +" "+ this.year);

        //---- update events count:
        this.eventsCountMsg();

        //----view buttons
        this.viewButtons();

        //----next button
        this.nextButton();
        this.prevButton();
        // month/year update button
        this.updateDateButton();
        
        //search button // enter on input text
        this.searchEvent();        

        //----clear filters button
        this.clearFiltersButton();

        //----filters
        this.onChangeFilters();

        //---- create both views:
        this.gridView();        
        this.listView();

        //Set initial day value Calendar page -- KD
        var monthval = $('#eventsCalendarMonth').find(":selected").text();
        var yearval = $('#eventsCalendarYear').find(":selected").text();
        var monthYearVal = monthval + " " + yearval;
        $('#meetingDate').text(monthYearVal);
    }

    // updates message of number of events on top of the grid
    eventsCountMsg(){
        switch (this.filteredEvents.length) {
            case 0:
            $('#there').text('There are ');
            $('#eventsCount').text('no');
            $('#evts').text(' events');
                break;
            case 1:
            $('#there').text('There is ');
            $('#eventsCount').text(this.filteredEvents.length);
            $('#evts').text(' event');
                break;          
            default:
            $('#there').text('There are ');
            $('#eventsCount').text(this.filteredEvents.length);
            $('#evts').text(' events');
        }        
    }

    // create iteration string date
    createIterationDate(day, month, year){
        let iterationDay = ("0" + day).slice(-2);
        let iterationDate = `${month}-${iterationDay}-${year}`;        
        return iterationDate;
    }

    // return the day of a given date
    dayOfTheMonth(stringDate)
    { 
        return stringDate.split(/-/)[1];
    }

    // return the amount of weeks in a month, Weeks start on Sunday
    weekCount(year, month_number) {
        // month_number is in the range 1..12
        var firstOfMonth = new Date(year, month_number-1, 1);
        var lastOfMonth = new Date(year, month_number, 0);
        var used = firstOfMonth.getDay() + lastOfMonth.getDate();
    
        return Math.ceil( used / 7);
    }

    // views
    viewButtons(){
        //view month button
        $('#meetingAndEvents #viewMonth').click(function(e){
            $(this).addClass('active').attr('aria-current','true');
            $('#meetingAndEvents #viewList').removeClass('active').attr('aria-current','false');
            $('.events-table-container').addClass('d-md-block ');
            $('.events-list-container').removeClass('d-md-block ').addClass('d-md-none');
            $('#calendarScreenReaderSectionTitle').text('Grid view');
        });
    
        //view list button
        $('#meetingAndEvents #viewList').click(function(e){
            $(this).addClass('active').attr('aria-current','true');
            $('#meetingAndEvents #viewMonth').removeClass('active').attr('aria-current','false');
            $('.events-table-container').removeClass('d-md-block ');
            $('.events-list-container').removeClass('d-md-none').addClass('d-md-block ');
            $('#calendarScreenReaderSectionTitle').text('List view');
        });    
    }

    //next
    nextButton(){
        var self = this;
        $('#nextMonth').click(function(e){           
            let year = parseInt( self.stringEventsDate[2] ); //from events date             
            let month = parseInt( self.stringEventsDate[0] ) +1; // from events date             
            if (month > 11){
                month = 0;
                year += 1;
            }       
            // console.log('year: ', year);
            // console.log('month: ', month);     
            let searchTerm = $("#inputSearchEvents").val().trim().length > 2 ? $("#inputSearchEvents").val().trim() : "";
            let category = $('#categoriesDropdown').val();
            let type = $('#typesDropdown').val();
            var service = $('#meetingAndEvents').attr('data-service'); // "/data/events02.json"; //$('#meetingAndEvents').attr('data-service');
            var dataParameters = { 'month': month, 'year': year }; //'search': searchTerm, 'category': category, 'type': type 
            $('#meetingAndEvents .loader-container').show(); 

            $.get(service, dataParameters, function(data, status, xhr) {             
                //console.log(data.EventsResponse);
                self.updatePage(data.EventsResponse, category, type, searchTerm);
            })           
            .fail(function(jqxhr, settings, ex) {  
                $('#meetingAndEvents').append('<p>Service Error '+ ex +'</p>');
            })
            .always(function () {
                //console.log('Request finished');    
                $('#meetingAndEvents .loader-container').hide(); 
            });
        });
    }

    //prev
    prevButton(){
        var self = this;
        $('#previousMonth').click(function(e){ 
            let year = parseInt( self.stringEventsDate[2] ); //from events date             
            let month = parseInt( self.stringEventsDate[0] ) -1; // from events date                      
            if (month < 0){
                month = 11;
                year -= 1;
            }  
            // console.log('year: ', year);
            // console.log('month: ', month);
            let searchTerm = $("#inputSearchEvents").val().trim().length > 2 ? $("#inputSearchEvents").val().trim() : "";
            let category = $('#categoriesDropdown').val();
            let type = $('#typesDropdown').val();
            var service = $('#meetingAndEvents').attr('data-service'); // "/data/events00.json"; //$('#meetingAndEvents').attr('data-service');
            var dataParameters = { 'month': month, 'year': year }; //'search': searchTerm, 'category': category, 'type': type 
            $('#meetingAndEvents .loader-container').show(); 
            
            $.get(service, dataParameters, function(data, status, xhr) {                            
                //console.log(data.EventsResponse);
                self.updatePage(data.EventsResponse, category, type, searchTerm);
            })           
            .fail(function(jqxhr, settings, ex) {  
                $('#meetingAndEvents').append('<p>Service Error '+ ex +'</p>');
            })
            .always(function () {
                //console.log('Request finished');    
                $('#meetingAndEvents .loader-container').hide(); 
            });
        });
    }

    // go button month/year update
    updateDateButton(){
        var self = this;
        $('#updateDate').click(function(e){
            let month = parseInt($("#eventsCalendarMonth").val());
            let year = $("#eventsCalendarYear").val();
            let searchTerm = $("#inputSearchEvents").val().trim().length > 2 ? $("#inputSearchEvents").val().trim() : "";
            let category = $('#categoriesDropdown').val();
            let type = $('#typesDropdown').val();
            var service = $('#meetingAndEvents').attr('data-service'); // "/data/events02.json"; //$('#meetingAndEvents').attr('data-service');
            var dataParameters = { 'month': month, 'year': year }; //'search': searchTerm, 'category': category, 'type': type 
            $('#meetingAndEvents .loader-container').show(); 

            $.get(service, dataParameters, function(data, status, xhr) {             
                //console.log('self.mainListEvents: ', self.mainListEvents);
                //console.log('data.EventsResponse: ', data.EventsResponse);
                self.updatePage(data.EventsResponse, category, type, searchTerm );
            })           
            .fail(function(jqxhr, settings, ex) {  
                $('#meetingAndEvents').append('<p>Service Error '+ ex +'</p>');
            })
            .always(function () {
                //console.log('Request finished');    
                $('#meetingAndEvents .loader-container').hide(); 
            });
        });
    }

    //onchage filter dropwdowns (category, type)
    onChangeFilters(){
        var self = this;
        $('#categoriesDropdown, #typesDropdown, #searchEvents').change(function(){    
            //console.log('categoriesDropdown executed');
            let searchTerm = $("#inputSearchEvents").val().trim().length > 2 ? $("#inputSearchEvents").val().trim() : "";
            let category = $('#categoriesDropdown').val();
            let type = $('#typesDropdown').val();
            let objEventsResponse = {
                'Events': self.mainListEvents,
                "EventsDate": self.stringEventsDate.join('-'),
                "TodaysDate": self.stringTodaysDate.join('-')
            }

            self.updatePage(objEventsResponse, category, type, searchTerm );
        });
    }

    //search event -> assign events: click, on Enter
    searchEvent(){
        var self = this;
        $('#searchEvents, #btnSearchEvents').click(function(){ 
            self.searchButton();
        });
        $('#inputSearchEvents').keypress(function (e) { 
            if (e.which == 13) {                
                self.searchButton();
            }
        });
    }

    //search button (search functionality, send params to updatePage)
    searchButton(){    
            var self = this;            
            //console.log('search button clicked');
            let searchTerm = $("#inputSearchEvents").val().trim().length > 2 ? $("#inputSearchEvents").val().trim() : "";
            let category = $('#categoriesDropdown').val();
            let type = $('#typesDropdown').val();
            let objEventsResponse = {
                'Events': self.mainListEvents,
                "EventsDate": self.stringEventsDate.join('-'),
                "TodaysDate": self.stringTodaysDate.join('-')
            }
            //console.log('search term: ', searchTerm);
            self.updatePage(objEventsResponse, category, type, searchTerm );        
    }

    //update page
    updatePage(EventsResponse, category, type, searchTerm){

        this.mainListEvents =  EventsResponse.Events;
        this.filteredEvents = this.filterEvents(category, type, searchTerm);

        this.stringEventsDate = EventsResponse.EventsDate.split(/-/);
        this.eventsDate = new Date(this.stringEventsDate[2], this.stringEventsDate[0], this.stringEventsDate[1] ); //
        this.stringTodaysDate = EventsResponse.TodaysDate.split(/-/);
        this.todaysDate = new Date(this.stringTodaysDate[2], this.stringTodaysDate[0], this.stringTodaysDate[1] ); //year/month/day 

        this.year = this.eventsDate.getFullYear();
        this.month = ("0" + (this.eventsDate.getMonth() )).slice(-2) // 0=jan, 1=feb, 2=mar, 3 apr, 4=may // it is calculated: 01,02,03... 10,11,12        
        this.day = 1; //calculated 

        $('#eventsCalendarYear').val(this.year);
        $('#eventsCalendarMonth').val(+this.month);
        $('.events-grid-table caption').text(this.monthsList[+this.month] +" "+ this.year);

        this.eventsCountMsg();        
        this.gridView();
        this.listView();

        this.disabledPrevNext(); //disable prev/next buttons on calendar update

        // show clear filters button:
        if(type != "All" || category != "All" || searchTerm.length != 0)
            $('#clearFilters').show();
        else
            $('#clearFilters').hide();
    }

    //filter Events
    filterEvents(category, type, searchTerm){
        let filterByCategory = [];
        let filterByCategoryType = [];
        let filterByCategoryTypeTitleParagraph = [];

        //filter categories
        if(category != "All")
            filterByCategory = _.filter(this.mainListEvents, { 'Category': category } );
        else
            filterByCategory = this.mainListEvents;     
       
        //filter types
        if(type != "All")
            filterByCategoryType = _.filter(filterByCategory, { 'Type': type } );
        else
            filterByCategoryType = filterByCategory;

        //console.log('filterByCategoryType: ', filterByCategoryType);

        filterByCategoryTypeTitleParagraph =  _.filter(filterByCategoryType, function(obj) {                        
            // return elements that title or description contains search string
            // return _.includes(obj.Title.toLowerCase(), searchTerm.toLowerCase()) || _.includes(obj.Paragraph.toLowerCase(), searchTerm.toLowerCase())
            return _.includes(obj.Title.toLowerCase(), searchTerm.toLowerCase() );
        });
        //console.log('filterByCategoryTypeTitleParagraph: ', filterByCategoryTypeTitleParagraph);
        return filterByCategoryTypeTitleParagraph; 
               
    }

    //erase filters and updatePage
    clearFiltersButton(){
        var self = this;   
        $('#clearFilters').click(function(e){
            e.preventDefault();
            console.log('clearFilters clicked!');

            //erase all filters:
            $('#categoriesDropdown').val("All");
            $('#typesDropdown').val("All");
            $('#inputSearchEvents').val("");

            let objEventsResponse = {
                'Events': self.mainListEvents,
                "EventsDate": self.stringEventsDate.join('-'),
                "TodaysDate": self.stringTodaysDate.join('-')
            }
            self.updatePage(objEventsResponse, "All", "All", "" );    

            //hide button:
            $(this).hide();
        });
    }

    //return the name of the day giving a date
    nameOfDay(theDate) {
        //console.log('theDate', theDate);
        //var a = new Date();
        //console.log('new Date: ', a);
        var days = new Array(7);
        days[0] = "Sunday";
        days[1] = "Monday";
        days[2] = "Tuesday";
        days[3] = "Wednesday";
        days[4] = "Thursday";
        days[5] = "Friday";
        days[6] = "Saturday";
        var r = days[theDate.getDay()];
        return r;        
    }

    // disable prev/next buttons when reach begining or end, should be executed on change year and month dropdowns, on change prev/next buttons
    disabledPrevNext(){
        //console.log('disable prev/next');
        var beginingDate = new Date($('#eventsCalendarYear option:first').val(), '00', '31' ); //year/month/day 
        var endingDate = new Date($('#eventsCalendarYear option:last').val(), '11', '01' ); //year/month/day 
        
        // console.log('begining: ', beginingDate );
        // console.log('end: ', endingDate );
        // console.log('events date: ', this.eventsDate); // eventsDate: year/month/day 

        if( this.eventsDate <= beginingDate )
            $('#previousMonth').addClass('disabled').attr('disabled', true);
        else
            $('#previousMonth').removeClass('disabled').attr('disabled', false);
            
        if( this.eventsDate >= endingDate )
            $('#nextMonth').addClass('disabled').attr('disabled', true);
        else
            $('#nextMonth').removeClass('disabled').attr('disabled', false);

    }

    // grid view
    gridView(){
        // vars needed to create grid
        let firstDay = ( new Date(this.year, this.month)).getDay(); //new Date(year, month, day, hours, minutes, seconds, milliseconds) // getDay returns the day of the week, if date set only in month then it retuns the 1st day of the month in a week        
        let remainingDays = 7 - (new Date(this.year, parseInt(this.month) +1)).getDay(); //get first day of next month, minus 7 = this is remaining days to fill the grid
        let daysInMonth = 32 - new Date(this.year, this.month, 32).getDate(); // new Date(year, month, day, hours, minutes, seconds, milliseconds) //remeber to do < not equal      
        
        let weeksInMonth = this.weekCount(this.year, parseInt(this.month) + 1) //year, month_number

        //---- clearing all previous cells
        let tableBody = $('#eventsGridBody').html(''); // body of the calendar        
        //---- create all cells in tbody (rows and cells) // max rows could be 6 thats why 0-5        
        let month_day = 1;
        // for (let i = 0; i < 6; i++) { //here would be a good idea to calculate howmany weeks do we have, because sometimes could be 4 or 5 or 6 and the else if could be execute twice
        for (let i = 0; i < weeksInMonth; i++) { //here would be a good idea to calculate howmany weeks do we have, because sometimes could be 4 or 5 or 6 and the else if could be execute twice
            // creates a table row
            let row = document.createElement("tr");

            //create individual cells, filing them up with data.
            for (let j = 0; j < 7; j++) {
                //first row empty cells: (previous month)
                if (i === 0 && j < firstDay) {
                    let cell = document.createElement("td");
                    $(cell).addClass("no-events nextmonth")
                    row.appendChild(cell);
                }

                // break if days in month are completed, fill remaining spaces
                else if (month_day > daysInMonth) {  
                    for(let z = 0; z < remainingDays; z++ ){                   
                        let cell = document.createElement("td");
                        $(cell).addClass("no-events nextmonth")
                        row.appendChild(cell);
                    }
                    break;
                }

                // cells current month, that way is not filling cells to complete last row
                else {
                    let cell = document.createElement("td");
                    //create iteration date string
                    let iterationDate = this.createIterationDate(month_day, this.month, this.year);
                    // filter day events based on createIterationDate
                    let dayEvents = _.filter(this.filteredEvents, function(o) { return o.Date == iterationDate });
                    
                    let cellText = ''
                    //could be a function:
                    if(dayEvents.length != 0){
                        cellText = `
                        <span class="et-date">
                            <span aria-hidden="true">${month_day}</span>
                            <span class="sr-only">${this.monthsList[+this.month]} ${month_day}, ${this.year}</span>
                        </span>
                        `;  
                        $(cell).append('<ul></ul>');
                        
                        for( let k=0; k < dayEvents.length; k++){ 
                            if(dayEvents[k].Cancelled == true)
                                var cancelledOption = `<span class="event-cancelled"> - Canceled </span>`
                            else
                                var cancelledOption = ``
                            
                            // for each event do template:
                            var listItem = `
                            <li lang="de" class="calendar-event">
                                <a href="${dayEvents[k].Link}" class="event-description">
                                <span class="event-time">${dayEvents[k].Time.replace("PM", "p.m.").replace("AM", "a.m.").replace(":00", "   ").substring(0, 10).toLowerCase()}</span>
                                    <span class="event-type">${dayEvents[k].Type}</span>
                                    <span class="event-title">${dayEvents[k].Title}</span>
                                    ${cancelledOption}
                                </a>                                        
                            </li>`;
                            $(cell).find('ul').append(listItem);
                        }                                      
                    }
                    else{
                        cellText = `
                        <span class="et-date">
                            <span aria-hidden="true">${month_day}</span>
                            <span class="sr-only">${this.monthsList[this.eventsDate.getMonth()]} ${month_day}, ${this.year}</span>
                        </span>`;
                    }                                        
                
                    // could be a function or the same as the logic for event one or without event
                    // this checks todays day to mark it as different
                    if (month_day == this.todaysDate.getDate() && this.year == this.todaysDate.getFullYear() && this.month == this.todaysDate.getMonth()) {
                        cell.classList.add("bg-info"); //today!
                    } // color today's date   
                                        
                    $(cell).prepend(cellText);
                    $(row).append(cell);
                    month_day++;
                }
            }
            tableBody.append(row); // appending each row into calendar body.
            //KD - Update date
            var monthvalue = $('#eventsCalendarMonth').find(":selected").text();
            var yearvalue = $('#eventsCalendarYear').find(":selected").text();
            var monthYear = monthvalue + " " + yearvalue;
            $('#meetingDate').text(monthYear); 
        }

        //add bottom border on hover at bottom:
        tableBody.find('td').append('<div class="red-border-hover" aria-hidden="true">&nbsp;</div>');

        //Event Click for Month and Year DDL changes
        //By KD to handle text changes base on ddl selection
        $('#eventsCalendarMonth').change(function(e)
        {   
            e.stopImmediatePropagation();
            e.preventDefault();
            var monthvalue = $('#eventsCalendarMonth').find(":selected").text();
            var yearvalue = $('#eventsCalendarYear').find(":selected").text();
            var monthYear = monthvalue + " " + yearvalue;
            $('#meetingDate').text(monthYear);
            $("#updateDate").click();
         });
        $('#eventsCalendarYear').change(function(e)
        {
            e.stopImmediatePropagation();
            e.preventDefault();
            var monthvalue2 = $('#eventsCalendarMonth').find(":selected").text();
            var yearvalue2 = $('#eventsCalendarYear').find(":selected").text();
            var monthYear2 = monthvalue2 + " " + yearvalue2
            $('#meetingDate').text(monthYear2);
            $("#updateDate").click();
        });

    }

    listView(){
        // clearing all previous cells        
        let listBody = $('#eventsListBody').html(''); 

        let datesArray = [];
        datesArray =_.uniq( _.map(this.filteredEvents, 'Date') ); //get dates and exclude repeated      
        //console.log('datesArray: ', datesArray); //here there is an error when 00-xx-2021 becasue 00 in a string does not work

        for( let i=0; i < datesArray.length; i++){ 
            let cell = document.createElement("li");
            $(cell).addClass('row no-gutters has-events');
            cell.innerHTML = `            
                <h5 class="col-12 et-date">
                   ${this.nameOfDay( new Date(this.year, +this.month, +this.dayOfTheMonth(datesArray[i]) ) )}, ${this.monthsList[+this.month]} ${this.dayOfTheMonth( datesArray[i] )}, ${this.year}
                </h5>                                                                 
            `;

            //create ul for day events list and set the aria-label
            let ulContainer = `<ul aria-label="Events for ${this.nameOfDay( new Date(this.year, +this.month, +this.dayOfTheMonth(datesArray[i]) ) )}, ${this.monthsList[+this.month]} ${this.dayOfTheMonth( datesArray[i] )}, ${this.year}"></ul>`
            $(cell).append(ulContainer); //adds the ul for the list of events in that day

            let collection = _.filter(this.filteredEvents, { 'Date': datesArray[i] } );

            for( let j=0; j<collection.length; j++  ){
                if(collection[j].Cancelled == true)
                    var cancelledOption = `<span class="event-cancelled"> - Canceled </span>`
                else
                    var cancelledOption = ``

                let cellText = `
                <li class="calendar-event col-12">
                    <a href="${collection[j].Link}" class="event-description row">
                        <div class="col-5 col-md-3 col-lg-2 d-flex align-items-center desc-time">
                            <span class="event-time">${collection[j].Time.replace("PM", "p.m.").replace("AM", "a.m.").replace(":00", "   ").substring(0, 10).toLowerCase()}</span>
                        </div>
                        <div class="col-7 col-md-9 desc-type-and-title">
                            <span class="event-type">${collection[j].Type}</span>
                            <span class="event-title">${collection[j].Title} ${cancelledOption}</span>
                        </div>                                                                    
                    </a>                                        
                </li>
                `
                $(cell).find('ul').append(cellText);
            }

            listBody.append(cell);
            //KD - Update date
            var monthvalue = $('#eventsCalendarMonth').find(":selected").text();
            var yearvalue = $('#eventsCalendarYear').find(":selected").text();
            var monthYear = monthvalue + " " + yearvalue;
            $('#meetingDate').text(monthYear);   
            
        }
    }

}