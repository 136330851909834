export function meetingArchive() {
    if( $('#meetingArchive').length != 0 ){
        const app = new Vue({
            el: '#meetingArchive',
            data: {
                yearList: [],
                service: $('#meetingArchive').attr('data-service'),
                year: "",
            },
            created: function () {
                this.getYear();
            },
            methods: {
                yearMeetings: function (year) {
                    this.year = year;
                    // this.service = '../data/meetingArchiveResponse.json';
                    //$('#meetingArchive img.loader').show();
                    this.getYear();
                },
                getYear() {
                    let self = this;
                    let dataParameters = {
                        'year': this.year
                    };
                    $.get(this.service, dataParameters, function (data) {
                            //console.log('Request success!!!', data);
                            self.yearList = data;
                        })
                        .always(function () {
                            $('#meetingArchive img.loader').hide();
                        });
                }
            }
        });
    
        // Position Resources Module
        if ($(".meeting-archive").length) {
            $(".contact-card").css('margin-top', '88px');
        }
        if ($(".col.my-4.py-4").length) {
            $(".contact-card").css('margin-top', '49px');
        }
    }

}