let createCookie = (name, value, days, path) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else expires = "";
    document.cookie = name + "=" + value + expires + "; path=" + path;
}

let readCookie = (name) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

let generateHash = (alertDesc) => {
    var hash = 0;
    if (alertDesc.length == 0)
        return hash;
    for (let i = 0; i < alertDesc.length; i++) {
        var charCode = alertDesc.charCodeAt(i);
        hash = ((hash << 7) - hash) + charCode;
        hash = hash & hash;
    }
    return hash;
}

let readAlertCookie = (name) => {
    let nameEQ = name + "=";

    let ca = document.cookie.split(';');
   
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}


// Set/update cookie for Consent Banner
export let consentBanner = () => {
    const cookiePath = "/";
    const cookieExpiry = $('#cookies-consent-banner .cookie-expiry').val();
    const cookieConsentBannerId = $('#consentBannerId').val();
    $('#agree-terms').click(() => {
        $('#cookies-consent-banner').hide();
        createCookie('seenCookieBanner', cookieConsentBannerId, cookieExpiry, cookiePath);
    });
    let cookieBanner = readCookie('seenCookieBanner');
    if (cookieBanner != null && cookieBanner == cookieConsentBannerId) {
        $('#cookies-consent-banner').hide();
    } else {
        $('#cookies-consent-banner').animate({
            opacity: 1
        }, 500, 'linear');
    }
}

// Set/update Cookie for Alert Banner
export let alertBanner = () => {
    const cookiePath = "/";
    const cookieAlertExpiry = $('#cookies-alert .cookie-expiry').val();
    const cookieAlertId = $('#cookies-alert #alertId').val();
    const cookieAlertVal = $('#cookies-alert #alertDescText').val();
    const cookieAlertValHash = generateHash(cookieAlertVal);
    
    $('#cookies-alert #close-alert').click(() => {     
        $('#cookies-alert').hide();
        $('.header-top').removeClass('pushCookieAlert');
        createCookie('closeCookieAlert', cookieAlertValHash, cookieAlertExpiry, cookiePath);       
    });

    let cookieAlert = readCookie('closeCookieAlert');
    
    if (cookieAlertVal != null ) {
        if (cookieAlert != null) {

            if (cookieAlert == cookieAlertValHash ) {   //cookie exists for this alert             
                $('#cookies-alert').hide();
                $('.header-top').removeClass('pushCookieAlert');
            }
            else {               
                $('#cookies-alert').animate({
                    opacity: 1
                }, 500, 'linear');
                $('.header-top').addClass('pushCookieAlert');
            }
        }
    }
}


// Delete Cookie Alert
// let deleteCookie = () => {
//     document.cookie = closeCookieAlert + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
// };