
// create the news list in home page
export function homeNewsLists(){
    if( $('#newsHomeList').length !=0 ){
        //create vue app for news home list
        var newsHomeList = new Vue({
            el: '#newsHomeList',
            data: {                
                page: 1,
                newsList: []
            },
            mounted: function () {
                var self = this;
                var service = $('#newsHomeList').attr('data-service');
                var dataParameters = { 'page': 1, 'category': 'All', 'search': '' };  

                $.get(service, dataParameters, function(data, status, xhr) { 
                    //console.log('Request success!!!', data);           
                    self.newsList = data.NewsResponse.News;                      
                })           
                .fail(function(jqxhr, settings, ex) { 
                    //console.log('Request failed, ', ex, jqxhr);
                    // if(jqxhr.status == 404 || ex == 'Not Found') 
                    // { 
                    //     console.log('There was a 404 error.'); 
                    // } 
                    $('#newsHomeList').append('<p>Service Error '+ ex +'</p>');
                })
                .always(function () {
                    //console.log('Request finished');    
                    $('#newsHomeList img.loader').hide(); 
                });
            }
        });
    }
}

// create the news list in news and publications
export function newsAndPublicationsList(){
    if( $('#newsAndPublicationsMain').length != 0 ){
    
        //create vue app for news and publications list
        var newsAndPublicationsList = new Vue({
            el: '#newsAndPublicationsMain',
            data: {
                total_count: 0,
                current_count: 0,
                page: 1,            
                newsList: [],
                categoriesList: [],
                currentCategory: 'All',
                finishedList: false, 
                searchTerm: "",

            },
            updated: function(){
                $('#categoriesDropdown').val(this.currentCategory)
            },

            mounted: function () {
                var self = this;
                
                //read url query string
                var urlParameter = getParameterByName('category');

                if(Boolean(urlParameter)){
                    self.currentCategory = urlParameter;                                
                } 
    
                var service = $('#newsAndPublicationsMain #newsAndPublicationsList').attr('data-service');
                var dataParameters = { 'page': 1, 'category': self.currentCategory, 'search': '' };  

                $.get(service, dataParameters, function(data, status, xhr) { 
                    //console.log('Request success!!!', data);                    
                    self.newsList = data.NewsResponse.News;    
                    self.total_count = data.NewsResponse.TotalCount;
                    self.current_count = self.newsList.length;
                    self.categoriesList = data.NewsResponse.Categories; 
                    self.searchTerm = data.NewsResponse.SearchTerm; 
                    //check if there are more items to show load more
                    if( self.current_count >= self.total_count ) $('#newsAndPublicationsList #loadMoreNews').hide();
                    else $('#newsAndPublicationsList #loadMoreNews').show();
                    
                    //show clear filters in case there is a url parameter
                    if(self.currentCategory != 'All') $("#clearFilters").show();

                })           
                .fail(function(jqxhr, settings, ex) { 
                    $('#newsHomeList').append('<p>Service Error '+ ex +'</p>');
                })
                .always(function () { 
                    $('#newsAndPublicationsList img.loader').hide();                                                                 
                });
            },

            methods: {
                //load more button
                loadMoreList: function (e) {
                    e.preventDefault();
                    var self = this;
                    var service = $('#newsAndPublicationsMain #newsAndPublicationsList').attr('data-service');
                    var page = self.page + 1;
                    var searchTerm = $('#inputTopicSearchNews').val();
                    var dataParameters = { 'page': page, 'category': self.currentCategory, 'search': searchTerm };                   

                    $('#newsAndPublicationsList img.loader').show();        

                    $.get(service, dataParameters, function(data, status, xhr) { 
                        //console.log('Request success!!!', data.NewsResponse);           
                        if( data.NewsResponse.News.length != 0 ){
                            self.newsList = self.newsList.concat(data.NewsResponse.News);
                            self.current_count = self.newsList.length;
                            self.page = page;
                            self.searchTerm = data.NewsResponse.SearchTerm;                             
                        }
                        //check if there are more items to show load more
                        if( self.current_count >= self.total_count ) $('#newsAndPublicationsList #loadMoreNews').hide();
                        else $('#newsAndPublicationsList #loadMoreNews').show();
                    })           
                    .fail(function(jqxhr, settings, ex) { 
                        $('#newsHomeList').append('<p>Service Error '+ ex +'</p>');
                        $('#newsAndPublicationsList #loadMoreNews').hide();
                    })
                    .always(function () { 
                        $('#newsAndPublicationsList img.loader').hide();                     
                    });
                },

                // list events from a category
                categorySelect: function(event){                      
                    //console.log('cateogry clicked! event: ', event.target.value);
                    var self = this;
                    var service = $('#newsAndPublicationsMain #newsAndPublicationsList').attr('data-service');
                    var searchTerm = $('#inputTopicSearchNews').val();
                    var dataParameters = { 'page': 1, 'category': event.target.value, 'search': searchTerm };

                    $('#newsAndPublicationsList img.loader').show();    

                    $.get(service, dataParameters, function(data, status, xhr) {                    
                        self.newsList = data.NewsResponse.News;    
                        self.page = 1;
                        self.total_count = data.NewsResponse.TotalCount;
                        self.current_count = self.newsList.length; //here current count because if need to count with jquery will count before it shows all items
                        // self.categoriesList = data.NewsResponse.Categories; //dont update dropdown list
                        self.currentCategory = event.target.value;
                        self.searchTerm = data.NewsResponse.SearchTerm; 
                        // show clear
                        $("#clearFilters").show();
                        //check if there are more items to show load more
                        //console.log('self.current_count: ', self.current_count);
                        //console.log('self.total_count: ', self.total_count);
                        if( self.current_count >= self.total_count ) $('#newsAndPublicationsList #loadMoreNews').hide();
                        else $('#newsAndPublicationsList #loadMoreNews').show();
                    })           
                    .fail(function(jqxhr, settings, ex) { 
                        console.log('Request failed, ', ex, jqxhr); 
                        $('#newsHomeList').append('<p>Service Error '+ ex +'</p>');
                    })
                    .always(function () {
                        console.log('Request finished');  
                        $('#newsAndPublicationsList img.loader').hide();                                    
                    });
                },

                //search button
                searchBtn: function(){
                    console.log('Search button clicked!');
                    var self = this;
                    var service = $('#newsAndPublicationsMain #newsAndPublicationsList').attr('data-service');
                    var searchTerm = $('#inputTopicSearchNews').val();
                    var dataParameters = { 'page': 1, 'category': self.currentCategory, 'search': searchTerm };

                    if($('#inputTopicSearchNews').val().trim().length > 2){
                        $('#newsAndPublicationsList img.loader').show();
                        $.get(service, dataParameters, function(data, status, xhr) {                    
                            self.newsList = data.NewsResponse.News;    
                            self.page = 1;
                            self.total_count = data.NewsResponse.TotalCount;
                            self.current_count = self.newsList.length;
                            //self.categoriesList = data.NewsResponse.Categories;    //dont update dropdown list
                            self.searchTerm = data.NewsResponse.SearchTerm;                         
                            //show clear
                            $("#clearFilters").show();
                            //check if there are more items to show load more
                            if( self.current_count >= self.total_count ) $('#newsAndPublicationsList #loadMoreNews').hide();
                            else $('#newsAndPublicationsList #loadMoreNews').show();
                        })           
                        .fail(function(jqxhr, settings, ex) { 
                            $('#newsHomeList').append('<p>Service Error '+ ex +'</p>');
                        })
                        .always(function () {                        
                            $('#newsAndPublicationsList img.loader').hide();                     
                        });
                    }
                    else{
                        return;
                    }
                   
                },

                clearFilters: function (e) {
                    e.preventDefault();
                    var self = this;
                    self.page = 1;
                    var service = $('#newsAndPublicationsMain #newsAndPublicationsList').attr('data-service');
                    
                    $('#categoriesDropdown').val('All');
                    self.currentCategory = 'All';

                    $('#inputTopicSearchNews').val('');
                    var searchTerm = $('#inputTopicSearchNews').val();

                    var dataParameters = { 'page': self.page, 'category': self.currentCategory, 'search': searchTerm };   
                    
                    $('#newsAndPublicationsList img.loader').show();    

                    $.get(service, dataParameters, function(data, status, xhr) {                    
                        self.newsList = data.NewsResponse.News;                            
                        self.total_count = data.NewsResponse.TotalCount;
                        self.current_count = self.newsList.length;
                        //self.categoriesList = data.NewsResponse.Categories; //dont update dropdown list
                        self.searchTerm = data.NewsResponse.SearchTerm; 
                        // hide clear filters
                        $("#clearFilters").hide();
                        //check if there are more items to show load more
                        if( self.current_count >= self.total_count ) $('#newsAndPublicationsList #loadMoreNews').hide();
                        else $('#newsAndPublicationsList #loadMoreNews').show();
                    })           
                    .fail(function(jqxhr, settings, ex) {                         
                        $('#newsHomeList').append('<p>Service Error '+ ex +'</p>');
                    })
                    .always(function () {
                        $('#newsAndPublicationsList img.loader').hide();                     
                    });                    
                }
            }
        });
    }
}

// module.exports.homeNewsLists = homeNewsLists(); 
// module.exports.newsAndPublicationsList = newsAndPublicationsList(); 


function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}