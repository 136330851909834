;(($, window, document) => {
  //defaults value
  let defaults = {
    width: 1024,
    next: '<span class="arrow">&rsaquo;</span>',
    prev: '<span class="back-arrow">&lsaquo;</span>',
    linkSwitch: 'false'
  }

  function MultiMenu (element, options) {
    //lets block
    this.options = $.extend({}, defaults, options)
    this.element = element
    this.init()
  }

  //call init function
  MultiMenu.prototype.init = function () {
    let self = this,
      element = this.element,
      attrId = element.attr('id'),
      attrClass = element.attr('class'),
      menuEnabled = false

    //add Overlay
    $('body').append('<div class="multilevelOverlay js-overlay"></div>')

    //create animate-menu function
    let openMenu = () => {
      $('body').toggleClass('bodyFixed')
      setTimeout(function () {
        $('.multilevelMenu ul').attr('class', '')
      }, 400)
    }

    //swipe function for open menu
    let SwipeOpenMenu = () => {
      //create swipe-selector
      if ($('.multilevelMenu').length > 0) {
        $('body').append('<span class="js-swipe"></span>')
        let touchstartX = 0,
          touchendX = 0
        // open menu on tap swipe
        $('.js-swipe, .multilevelMenu').on('touchstart', function (e) {
          touchstartX = e.originalEvent.changedTouches[0].pageX
        })

        $('.js-swipe').on('touchend', function (e) {
          touchendX = e.originalEvent.changedTouches[0].pageX
          if (touchendX > touchstartX) {
            //call animate-menu function
            openMenu()
          }
        })

        //close menu left-swipe
        $('.multilevelMenu').on('touchend', function (e) {
          touchendX = e.originalEvent.changedTouches[0].pageX
          if (touchendX < touchstartX - 20) {
            $('body').removeClass('bodyFixed')
            setTimeout(function () {
              $('.multilevelMenu ul').attr('class', '')
            }, 400)
          }
        })
      } else {
        return false
      }
    }

    //Mobile Lang Translate Codes ------------------------------------------------
    //----------------------------------------------------------------------------

    // $('#mbResetImg').click(function () {
    //   //alert("Do Reset");
    //   $('#item').toggle();
    // });

      $("#mbResetImg").click(function() {
        $("#item").toggle();
      });
      // Hide toggle menu when clicked outside
      $(document).on("click", function(event) {
        var trigger = $("#mbResetImg")[0];
        var dropdown = $("#item");
        if (dropdown !== event.target && !dropdown.has(event.target).length && trigger !== event.target) {
          $("#item").hide();
        }
      });

    $('#mbreset li').click(function () {
      //alert(this.id);
      $('#language').val(this.id)
      // Run Translate Function
      changeLanguageByLang(this.id)
      //Close Box
      $('#mbResetImg').trigger('click')
    })
    function changeLanguageByLang (lang) {
      //var language = document.getElementById("language").value;
      var language = lang
      var selectField = document.querySelector(
        '#google_translate_element select'
      )
      for (var i = 0; i < selectField.children.length; i++) {
        var option = selectField.children[i]
        // find desired langauge and change the former language of the hidden selection-field
        if (option.value == language) {
          selectField.selectedIndex = i
          // trigger change event afterwards to make google-lib translate this side
          selectField.dispatchEvent(new Event('change'))
          break
        }
      }
    }
    function togglediv (id) {
      $('#item').toggle()
    }

    //End Mobile Lang Translate Codes ------------------------------------------------
    //----------------------------------------------------------------------------

    //create work-menu function
    let mobileMenu = () => {
      let w = window.innerWidth ? window.innerWidth : $(window).width()

      if (w < self.options.width) {
        //remove attr
        if (!menuEnabled) {
          menuEnabled = true
          element.removeAttr('id')
          element.removeClass()
          element.addClass('multilevelMenu')
          //call swipe function
          SwipeOpenMenu()
          //add switch-button for children ul
          let link = element.find('a')
          link.append(function (indx, val) {
            let out = ''
            if (
              $(this)
                .parent('li')
                .find('ul').length > 0
            ) {
              out = self.options.next
            }
            return $(out).addClass('js-arrow')
          })
          //add back-button function
          element
            .find('li')
            .find('ul')
            .prepend('<li class="back js-back"></li>')

          //view next level menu full button

          //view prev level menu to click "back"
          $('.js-back').click(function () {
            $(this)
              .closest('ul.close-list')
              .removeClass('close-list')
            $(this)
              .closest('ul')
              .addClass('hidden-menu')
            if ($('.inner > ul').hasClass('close-list') == false) {
              $('.menu-text').show()
            }
            let that = this
            setTimeout(function () {
              $(that)
                .closest('ul')
                .removeClass('active-menu hidden-menu')
            }, 400)
          })
        }
      } else {
        if (menuEnabled) {
          //return attr
          menuEnabled = false
          element.attr('id', attrId)
          element.attr('class', attrClass)
          $('.js-arrow, .js-back, .js-swipe').detach()
        }

        $('body').removeClass('bodyFixed') //remove fixedClass to body
        element.find('ul').removeClass()
      }
    }

    //call work-menu function
    mobileMenu()

    //remove attr resize
    $(window).resize(function () {
      //call work-menu function
      mobileMenu()
    })

    //animate menu function Call
    $('.js-toggle')
      .add($('.js-overlay'))
      .on('click', function () {
        //call animate-menu function
        openMenu()
        if ($('.inner > ul').hasClass('close-list') == false) {
          $('.menu-text').show()
        }
      })
  }

  $.fn.mobileMenu = function (options) {
    new MultiMenu(this.first(), options)
    return this.first()
  }
})(jQuery, window, document)

export let initMobileMenu = () => {
  $('.menu').mobileMenu({
    width: 992,
    next:
      '<div class="next-arrow next-button"></div> <i class="fas fa-chevron-right"></i>',
    prev:
      '<div class="back-arrow back-button"></div><i class="fas fa-chevron-left fa-2"></i>',
    linkSwitch: 'false'
  })

  // Search From Mobile Navigation
  let refreshPage = () => {
    // var newUrl = window.location.origin + window.location.pathname + "?searchTerm=" + $(".search-mobile input").val();
    var newUrl =
      window.location.origin +
      '/SearchResults?searchTerm=' +
      $('.search-mobile input').val()
    window.location.href = newUrl
    return false
  }
  $('.search-mobile i').click(() => {
    refreshPage()
  })
  $('.search-mobile input').keypress(function (e) {
    if (e.which == 13) {
      refreshPage()
    }
  })
  // Mobile scroll for homepage
  $(window).scroll(function () {
    var scroll = $(window).scrollTop()
    if ($('#cookies-alert').is(':visible') && scroll === 0) {
      $('.header-top').addClass('pushCookieAlert')
    } else {
      $('.header-top').removeClass('pushCookieAlert')
    }

    if (
      $('.hero-home-container').length != 0 ||
      $('.hero-video-container').length != 0
    ) {
      if (scroll >= 40) {
        $('.header-top').addClass('white-theme')
      } else {
        $('.header-top').removeClass('white-theme')
      }
    }
  })
}
//remove this line
