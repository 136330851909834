require("@fancyapps/fancybox");

export function multimediaGallery() {
  $('[data-fancybox]').fancybox({
    // infobar : false,
    // buttons : false,
    buttons: [
      "close"
    ],
    caption: function (instance, item) {
      return $(this).closest('figure').find('figcaption').html();
    },
    btnTpl: {
      close: '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
        '<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38"><path id="path" d="M412.6,877.8,397.4,893l15.2,15.2-3.8,3.8-15.2-15.2L378.4,912l-3.8-3.8L389.8,893l-15.2-15.2,3.8-3.8,15.2,15.2L408.8,874Z" transform="translate(-374.6 -874)" fill="#fff"/></svg>' +
        "</button>",
      arrowLeft: '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
        '<div><svg xmlns="http://www.w3.org/2000/svg" width="22.8" height="38" viewBox="0 0 22.8 38"><path id="path" d="M397.4,893h0l-3.8,3.8h0L378.4,912l-3.8-3.8L389.8,893l-15.2-15.2,3.8-3.8,15.2,15.2h0Z" transform="translate(397.4 912) rotate(180)" fill="#fff"/></svg></div>' +
        "</button>",

      arrowRight: '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
        '<div><svg xmlns="http://www.w3.org/2000/svg" width="22.8" height="38" viewBox="0 0 22.8 38"><path id="path" d="M397.4,893h0l-3.8,3.8h0L378.4,912l-3.8-3.8L389.8,893l-15.2-15.2,3.8-3.8,15.2,15.2h0Z" transform="translate(-374.6 -874)" fill="#fff"/></svg></div>' +
        "</button>",
    },
    baseTpl: '<div class="fancybox-container" role="dialog" tabindex="-1">' +
      '<div class="fancybox-bg"></div>' +
      '<div class="fancybox-inner">' +
      '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;of&nbsp;<span data-fancybox-count></span></div>' +
      '<div class="fancybox-toolbar">{{buttons}}</div>' +
      '<div class="fancybox-navigation">{{arrows}}</div>' +
      '<div class="fancybox-stage"></div>' +
      '<div class="fancybox-caption"><div class=""fancybox-caption__body"></div></div>' +
      '</div>' +
      '</div>',
    youtube: {
      autoplay: 0
    },
    vimeo: {
      autoplay: 0
    }
  });

  // Show Image Description with keyboard tab
  $('.multimedia-gallery .card-columns .card').bind('focus, blur, keyup', function (e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 9) {
      $('.multimedia-gallery .card-columns .card figcaption').removeClass('posfigcaption');
      $(this).find('figcaption').addClass('posfigcaption');
    }
  });
}

// module.exports.multimediaGallery = multimediaGallery();