const newsItem = Vue.component('news-item', {
  props: ['thedata'],
  template: `
            <div>
                <div :class="{ 'pinned-article' : thedata.Pinned }">
                    <div class="d-flex flex-row mb-5 mt-0 flex-lg-row flex-column">
                        <div class="news-grid-img">
                            <p class="d-none"><i class="fas fa-thumbtack d-none"></i> Pinned article</p>
                            <a v-bind:href="thedata.Link">
                                <img v-bind:src="thedata.Image" alt="news image" loading="lazy">
                            </a>
                        </div>
                        <div class="mt-4 mt-lg-0 ml-lg-5 ml-0">
                            <div class="d-flex flex-column">
                                <div class="mb-1" v-if="thedata.Date || thedata.Category">
                                    <span class="small" v-if="thedata.Date"><i class="far fa-calendar-alt d-none"></i>{{ thedata.Date }}</span> <span v-if="thedata.Date && thedata.Category">|</span> 
                                    <span v-if="thedata.Category">
                                        <a class="small" v-bind:href="thedata.NewsAndPublicationsUrl + '?category=' + thedata.CategoryId">
                                            <span>{{thedata.Category|trim}}</span>
                                        </a>
                                    </span>
                                </div>
                                <a v-bind:href="thedata.Link">
                                    <h3 class="main-blue" v-html="thedata.Title"></h3>
                                </a>
                                <h4 v-if="thedata.Subtitle" v-html="thedata.Subtitle"></h4>
                                <p v-if="thedata.Paragraph" v-html="thedata.Paragraph"></p> 
                                <div class="mt-3 mt-lg-0">
                                <a v-bind:href="thedata.Link" class="btn btn-primary" v-bind:aria-label="'Read more about ' + thedata.Title"  >Read More</a>
                            </div>                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    `
})
